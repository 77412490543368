import cls from "./customHeader.module.scss"
import React, { FC, useEffect, useRef, useState } from "react";
import {ISecondHeader} from "../MainLayout";
import CustomDepartments from "./CustomDepartments";
import CustomSearch from "./CustomSearch";
import CustomIndicatorPanel from "./CustomIndicators/CustomIndicatorPanel";
import CustomIndicatorCart from "./CustomIndicators/CustomIndicatorCart";
import {useSelector} from "react-redux";
import {IState} from "../../../../types/state";
import {ArrowRoundedDown9x6Svg, DropMenusSVG} from "../../../../svg";
import CustomItemList from "./CustomItemList";
import Link from "next/link";
import Image from "next/image";
import {domainUrl} from "../../../../helper";

const SecondSection: FC<ISecondHeader> = (
    {
        categories,
        isTwoKings,
        dbName,
        selectedRate,
        selectedLocale,
        translationsData,
        backOrderValue,
        allowCheckoutValue,
        defaultLangCode,
        menus,
        logoPath,
        isMobile
    }
) => {
    const openSearch = useSelector((state: IState) => state.mobileMenu.searchOpen)

    const dropDownRef = useRef<any>(null);
    const containerRef = useRef<any>(null);
    const [width, setWidth] = useState(containerRef);
    const [dropOpen, setDropOpen] = useState<boolean>(false);
    const [openMenus, setOpenMenus] = useState<number[]>([]);
    const [menusRowWidth, setMenusRowWidth] = useState(991);
    const gap = 42;
    let menusWidth = 170;
    let isOverflow = false;
    const letterSize = 8; // average number of each letter

    useEffect(() => {
        setMenusRowWidth(containerRef.current?.offsetWidth)
    }, [containerRef.current?.offsetWidth]);

    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            if (dropDownRef.current && !dropDownRef.current.contains(e.target as Node)) {
                setDropOpen(false);
            }
        }
        window.addEventListener("mousedown", handleClick)

        return () => window.removeEventListener("mousedown", handleClick)
    }, [dropDownRef])

    useEffect(() => {
        const handleResize = () => {
            setWidth(containerRef.current.clientWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener('resize', handleResize);

    }, [menus, width]);

    const toggleDrop = () => {
        setDropOpen(prevState => !prevState)
    }

    const toggleSub = (id: number) => {
        if (openMenus.some(subId => subId === id)) {
            setOpenMenus((prevState) => prevState.filter(subId => subId !== id))
        } else {
            setOpenMenus(prevState => [...prevState, id])
        }
    }


    const renderMenus = () => {
        let visibleCollection: JSX.Element[] = [];
        let dropdownCollection: JSX.Element[] = [];

        menus.forEach((item: any, index: number) => {
            const {name = "", id, children = [], url_key = "", target} = item;
            const existThisMenuId = openMenus.some(elem => elem === id)

            let arrow = null;
            let menu = null;
            let menu2 = null;


            if (children.length) {
                arrow = <div
                    className={`${cls.wrapper__bttn} ${existThisMenuId && cls.closed}`}>
                    <ArrowRoundedDown9x6Svg/>
                </div>
                menu = <CustomItemList items={children} openMenus={openMenus} toggleSub={toggleSub}/>

                menu2 = <div className={`${cls.deep__submenu} ${existThisMenuId && cls.opened__drop}`}>
                    <CustomItemList items={children} openMenus={openMenus} toggleSub={toggleSub}/>
                </div>
            }

            if (item && item.name) {
                const itemArrow = item.children.length ? 15 : 0
                const itemWidth = item.name.length * letterSize + itemArrow + gap;

                const visibleItem = <div key={index} className={cls.header_nav_menu_item}>
                    <Link href={url_key} prefetch={true}>
                        <a {...target}>{name} {arrow}</a>
                    </Link>
                    {menu}
                </div>

                const dropdownItem =
                    <div
                        key={index}
                        className={cls.header_nav_menu_item}
                        onClick={() => toggleSub(id as number)}
                    >
                        <div className={cls.menu_item__container}>
                            <button
                                className={`${cls.link__button} ${menu && cls.only__link}`}
                                onClick={e => e.stopPropagation()}
                            >
                                <Link href={url_key} prefetch={true}>
                                    <a
                                        {...target}
                                    >
                                        {name}
                                    </a>
                                </Link>
                            </button>
                            {arrow}
                        </div>
                        {menu2}
                    </div>

                if (isOverflow || menusWidth + itemWidth > menusRowWidth) {
                    isOverflow = true;
                    dropdownCollection.push(dropdownItem);
                } else {
                    menusWidth += itemWidth;
                    visibleCollection.push(visibleItem);
                }
            }
        })

        return (
            <>
                <div className={cls["nav-panel__departments"]} style={{marginLeft: '0'}}>
                    <CustomDepartments categories={categories} isTwoKings={isTwoKings} dbName={dbName}/>
                </div>
                {visibleCollection}
                {!!dropdownCollection.length &&
                    <div ref={dropDownRef} className={cls.dropMenus__container}>
                        <button
                            className={cls.wrapper__bttn}
                            onClick={toggleDrop}>
                            <DropMenusSVG/>
                        </button>
                        <div className={`${cls.dropMenus__body} ${dropOpen ? cls.opened__drop : null}`}>
                            {dropdownCollection}
                        </div>
                    </div>
                }
            </>
        )
    }

    const classSearchOpen = {
        true: `${cls["mobile-header__search--open"]} ${cls["mobile-header__search"]}`,
        false: cls["mobile-header__search"]
    }

    const showSearchPanelToggle: Record<"true" | "false", string> = {
        "true": "show-search-second",
        "false": "hide-search-second",
    }

    return <div className={`${cls.second_section} ${cls[showSearchPanelToggle[`${openSearch}`]]}`}>
        <div className={`${cls["nav-panel"]}`}>
            <div className={`${cls["nav-panel__container"]} container`}>
                <div className={`${cls["nav-panel__row"]}`}>
                    <div className={cls.logo_div}>
                        <Link href="/">
                            <a>
                                <Image
                                    src={domainUrl(`${dbName}/${logoPath}`)}
                                    alt="Logo"
                                    width={200}
                                    height={40}
                                    objectFit="contain"
                                    objectPosition="left"
                                    unoptimized={true}
                                    // TODO !!! this is that part which controlling CWV result
                                    priority
                                />
                            </a>
                        </Link>
                    </div>
                    {!isMobile ? (
                        <div ref={containerRef} className={cls.header_nav_menu}>
                            {renderMenus()}
                        </div>
                    ) : null}
                    <div className={`${cls["site-header___search"]} ${cls["site-header__indicators"]}`}>
                        <div className={`${cls["site-header__search"]} ${classSearchOpen[`${openSearch}`]}`}>
                            <CustomSearch
                                translationsData={translationsData}
                                selectedLocale={selectedLocale}
                                dbName={dbName}
                                selectedRate={selectedRate}
                                backOrderValue={backOrderValue}
                                defaultLangCode={defaultLangCode}
                            />
                        </div>
                        <div className={`${cls["nav-panel__indicators"]}`}>
                            <CustomIndicatorPanel translationsData={translationsData} />
                            <div className={`${cls["nav-panel__item_row"]}`}>
                                <CustomIndicatorCart
                                    allowCheckoutValue={allowCheckoutValue}
                                    translationsData={translationsData}
                                    dbName={dbName}
                                    selectedRate={selectedRate}
                                    locale={selectedLocale}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default SecondSection;