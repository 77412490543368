import React, {FC, Fragment} from "react"
import {useSelector} from "react-redux";
import {IState} from "../../../../../types/state";
import cls from "../customHeader.module.scss"
import CustomIndicator from "./CustomIndicator";
import CustomFailPanel from "./CustomFailPanel";
import {TranslationsType} from "../../../../../types/homePageTypes";

interface IIndicatorPanel {
    translationsData: TranslationsType;
}

const CustomIndicatorPanel: FC<IIndicatorPanel> = ({ translationsData }) => {
    const signed = useSelector((state: IState) => state.customer.authenticated);
    const wishlist = useSelector((state: IState) => state.wishlist);
    const wishIndicator = {
        "true": <CustomIndicator
            url="/wishlist"
            value={wishlist.length}
            icon="heartIcon"
            title={translationsData?.WishList}
            className=""
            dropdown=""
            iconWrapperClassName=""
        />,
        "false": <CustomFailPanel translationsData={translationsData}/>
    }

    return (
        <Fragment>
            <div className={`${cls["nav-panel__item_row"]} ${cls.heartButton}`}>
                {wishIndicator[`${signed}`]}
            </div>
        </Fragment>
    )
}

export default CustomIndicatorPanel;