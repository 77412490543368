import React, {FC, Fragment, useEffect, useMemo, useRef, useState} from "react";
import cls from "./customTopBar.module.scss"
import Link from "next/link";
import {DropDownArrow, Logout} from "../../../svg";
import {ICustomTopBar} from "./MainLayout";
import {domainUrl} from "../../../helper";
import {useRouter} from "next/router";
import CustomCurrDropDown from "./CustomCurrDropDown";
import CustomIndicatorDropDownBody from "./CustomHeader/CustomIndicators/CustomIndicatorDropDownBody";
import {useDispatch, useSelector} from "react-redux";
import {IState} from "../../../types/state";
import AccountNavigationMenu from "../../account/AccountNavigationMenu";
import shopApi from "../../../api/shop";
import {setAuth, setCustomerGroupID, setToken} from "../../../store/customer";
import {getCartDataMount} from "../../../store/cart";
import {wishListRemoveAllItems} from "../../../store/wishlist";
import { apiUrlWithStore } from "../../../helper";
import {AddCartToken} from "../../../store/token";

const CustomTopBar: FC<ICustomTopBar> = (
    {
        dbName,
        locales = [],
        currencies: [{
            code: selectedCurr = "",
            symbol: selectedSymbol = ""
        } = {}, ...currencies] = [],
        selectedLocaleImg,
        selectedLocaleName,
        selectedRate,
        selectedLocale,
        translationsData,
        isSocialLinksActive,
    }
) => {
    const [personal, setPersonal] = useState<string>('');
    const [
        {langMenuClassName, langDDAClassName},
        setOpenLang
    ] = useState({langMenuClassName: "none", langDDAClassName: ""});
    const [isClient, setIsClient] = useState(false);
    const [loginDropOpen, setLoginDropOpen] = useState<boolean>(false);
    const customer = useSelector((state: IState) => state.customer);
    const { cartToken } = useSelector((state: IState) => state.cartToken) || {}
    const router = useRouter();
    const signed = useSelector((state: IState) => state.customer.authenticated);
    const dispatch = useDispatch();
    const langRef = useRef<HTMLDivElement | null>(null)
    const searchRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            if (langRef && langRef.current && !langRef.current.contains(e.target as Node)) {
                setOpenLang({langMenuClassName: "none", langDDAClassName: ""})
            }
        }
        const handleSignInClick = (e: MouseEvent) => {
            if (searchRef && searchRef.current && !searchRef.current.contains(e.target as Node)) {
                setLoginDropOpen(false)
            }
        }

        document.addEventListener("mousedown", (e) => {
            handleSignInClick(e);
            handleClick(e);
        })

        return () => document.removeEventListener("mousedown", (e) => {
            handleSignInClick(e);
            handleClick(e)
        })
    }, [langRef])

    useEffect(() => {
        setIsClient(true);
    }, []);
    const handleLangClick = () => {
        setOpenLang(({langMenuClassName}) => {
            if (langMenuClassName === "none") {
                return {
                    langMenuClassName: "block",
                    langDDAClassName: "rotT_fms"
                }
            }
            return { langMenuClassName: "none", langDDAClassName: ""}
        })
    }

    const handleLangItemClick = (localeCode: string) => {
        router.push(router.asPath, router.asPath, {locale: localeCode}).then(r => r);
    }
    const handleAccDropClick = () => {
        setLoginDropOpen((prevState) => !prevState)
    }
    const logout = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        dispatch(setAuth(false));
        dispatch(setCustomerGroupID(1))
        dispatch(setToken(""));

        shopApi.getCustomerLogoutToken(customer.token)
            .then(item => {
                if (!item.ok) return;
                shopApi.getCheckoutCartToken()
                    .then(res => res.api_token && dispatch(AddCartToken(res.api_token)))
            })
        dispatch(getCartDataMount(cartToken, { token: "" }, selectedLocale, selectedRate ? selectedRate.code : ""));
        dispatch(wishListRemoveAllItems())
        router.push("/");
    };

    useEffect(() => {
        const abortController = new AbortController();
        const { signal } = abortController;

        if (customer.token) {
            fetch(apiUrlWithStore(`/api/customer/get?token=${customer.token}`), { signal })
                .then((response) => response.json())
                .then((res) => res && setPersonal(`${res.data.first_name} ${res.data.last_name}`))
                .catch((err) => console.error(err));
        }

        return () => abortController.abort();
    }, [customer.token]);


    const links = useMemo(() => (
        [
            {
                title: translationsData.PersonalInformation,
                url: "profile",
                id: 1,
            },
            {
                title: translationsData.MyOrders,
                url: "orders",
                id: 2,
            },
            {
                title: translationsData.DownloadableProducts,
                url: "downloadable-products",
                id: 3,
            },
            {
                title: translationsData.Address,
                url: "addresses",
                id: 4,
            },
            {
                title: translationsData.SignOut,
                url: "logout",
                icon: <Logout />,
                id: 5,
            }
        ].map((link) => {
            const classes = `${cls["account-nav__item"]} ${router.route.includes(link.url)? cls["account-nav__item--active"] : ""}`
            const signOutProps = link.url === "logout" ? { onClick: logout, className: cls.logout } : {}
            return (
                <li key={link.id} className={classes}>
                    <Link href={`/account/${link.url}`}>
                        <a {...signOutProps}>
                            {link.icon ? <span>{link.icon}</span> : null}
                            {link.title}
                        </a>
                    </Link>
                </li>
            );
        })
    ), [router.route, router.locale]);

    return (
        <div className={cls["top-bar"]}>
            <div className="container">
                <div className={cls["top-bar-main"]}>
                    <div className={cls.topbar_buttons}>
                        <div
                            id="forLanguage"
                            className={cls["language-button"]}
                            onClick={handleLangClick}
                            ref={langRef}
                        >
                            <span>{selectedLocaleName}</span>
                            <img
                                src={domainUrl(`${dbName}${selectedLocaleImg}`)}
                                alt="Language"
                                width={23}
                                height={18}
                                loading="lazy"
                            />
                            {locales.length ? (
                                <Fragment>
                                    <DropDownArrow className={cls[langDDAClassName]}/>
                                    <div className={cls[`menu-${langMenuClassName}`]}>
                                        <div className={cls.menu}>
                                            {locales?.map(({code, id, name, locale_image}) => (
                                                <div key={id} className={cls.menu_item}
                                                     onClick={() => handleLangItemClick(code)}>
                                                    <div className={cls.menu__icon}>
                                                        <img
                                                            src={domainUrl(`${dbName}${locale_image}`)}
                                                            alt="Language"
                                                            width={23}
                                                            height={18}
                                                            loading="lazy"
                                                        />
                                                    </div>
                                                    <p className={cls.menu__title}>
                                                        {name}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Fragment>
                            ) : null}
                        </div>
                        {currencies.length ? (
                            <CustomCurrDropDown
                                selectedCurr={selectedCurr}
                                selectedSymbol={selectedSymbol}
                                currencies={currencies}
                            />
                        ) : null}
                    </div>
                    {isClient && signed ? <div className={`${cls["my_account"]} ${cls["nav-panel__item-custom"]}`}>
                        <Link href='/account/profile' prefetch={false}>
                            <a>{translationsData.MyAccount}</a>
                        </Link>
                        <div className={cls["nav-panel__account-nav"]}>
                            <AccountNavigationMenu links={links} username={personal}/>
                        </div>
                    </div> : (
                        <div className={cls["account_indicators"]} ref={searchRef}>
                            <div onClick={handleAccDropClick}>
                                <Link href="#" prefetch={false}>
                                    <a>{translationsData?.SignIn}</a>
                                </Link>
                            </div>
                            <div className={`${cls[`account-dropdown__${loginDropOpen ? 'opened' : 'closed'}`]}`}>
                                <CustomIndicatorDropDownBody
                                    setOpen={() => {}}
                                    translationsData={translationsData}
                                    selectedRate={selectedRate}
                                    selectedLocale={selectedLocale}
                                    isSocialLinksActive={isSocialLinksActive}
                                    dbName={dbName}
                                />
                            </div>
                            <span>|</span>
                            <Link href="/signup">
                                <a>{translationsData?.SignUp}</a>
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
};

export default CustomTopBar;
