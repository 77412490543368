export {default as Profile} from "-!svg-react-loader!./profile.svg";
export {default as AddNewAddress} from "-!svg-react-loader!./add-new-address.svg";
export {default as ApprovedIcon} from "-!svg-react-loader!./approvved.svg";
export {default as ArrowDown} from "-!svg-react-loader!./arrow-down.svg";
export {default as ArrowRoundedDown9x6Svg} from "-!svg-react-loader!./arrow-rounded-down-9x6.svg";
export {default as ArrowRoundedDown12x7Svg} from "-!svg-react-loader!./arrow-rounded-down-12x7.svg";
export {default as ArrowRoundedLeft6x9Svg} from "-!svg-react-loader!./arrow-rounded-left-6x9.svg";
export {default as ArrowRoundedLeft8x13Svg} from "-!svg-react-loader!./arrow-rounded-left-8x13.svg";
export {default as ArrowRoundedRight6x9Svg} from "-!svg-react-loader!./arrow-rounded-right-6x9.svg";
export {default as ArrowRoundedRight8x13Svg} from "-!svg-react-loader!./arrow-rounded-right-8x13.svg";
export {default as ArrowRoundedUp13x8Svg} from "-!svg-react-loader!./arrow-rounded-up-13x8.svg";
export {default as BlogSVG} from "-!svg-react-loader!./blog.svg";
export {default as BNBIcon} from "-!svg-react-loader!./BNBIcon.svg";
export {default as BUSDIcon} from "-!svg-react-loader!./BUSDIcon.svg";
export {default as DropdownCloseSVG} from "-!svg-react-loader!./caret-down.svg";
export {default as DropdownOpenSVG} from "-!svg-react-loader!./caret-up.svg";
export {default as Cart16Svg} from "-!svg-react-loader!./cart-16.svg";
export {default as CartFill} from "-!svg-react-loader!./cartFil.svg";
export {default as DownloadIcon} from "-!svg-react-loader!./download.svg";
export {default as DownloadCloudIcon} from "-!svg-react-loader!./download-cloud.svg";
export {default as CategorySVG} from "-!svg-react-loader!./categorys.svg";
export {default as CheckToastSvg} from "-!svg-react-loader!./check.svg";
export {default as Check9x7Svg} from "-!svg-react-loader!./check-9x7.svg";
export {default as Check12x9Svg} from "-!svg-react-loader!./check-12x9.svg";
export {default as Check100Svg} from "-!svg-react-loader!./check-100.svg";
export {default as CheckoutFailSVG} from "-!svg-react-loader!./checkout-fail.svg";
export {default as ContactusSVG} from "-!svg-react-loader!./contact-us.svg";
export {default as DropMenusSVG} from "-!svg-react-loader!./menus_dropdown.svg";
export {default as ContactsSVG} from "-!svg-react-loader!./contacts.svg";
export {default as CookieSVG} from "-!svg-react-loader!./cookie-image.svg";
export {default as Cross10Svg} from "-!svg-react-loader!./cross-10.svg";
export {default as CloseSvg} from "-!svg-react-loader!./close-x.svg";
export {default as Cross20Svg} from "-!svg-react-loader!./cross-20.svg";
export {default as CrosselSvg} from "-!svg-react-loader!./crossSell.svg";
export {default as CustomSelectArrow} from "-!svg-react-loader!./custom-select_arrow.svg";
export {default as CustomSelectSelectedDel} from "-!svg-react-loader!./custom-select_selected-del.svg";
export {default as CVV} from "-!svg-react-loader!./cvv.svg";
export {default as CarouselPrev} from "-!svg-react-loader!./carouselPrev.svg";
export {default as CarouselNext} from "-!svg-react-loader!./carouselNext.svg";
export {default as EditAddress} from "-!svg-react-loader!./Edit-address.svg";
export {default as Facebook} from "-!svg-react-loader!./facebook.svg";
export {default as FailSvg} from "-!svg-react-loader!./fail.svg";
export {default as File} from "-!svg-react-loader!./file.svg";
export {default as Github} from "-!svg-react-loader!./github.svg";
export {default as Google} from "-!svg-react-loader!./google.svg";
export {default as Orders} from "-!svg-react-loader!./orders.svg";
export {default as ArrowBackSvg} from "-!svg-react-loader!./Icon-arrow-round-back.svg";
export {default as ImageIcon} from "-!svg-react-loader!./image_icon.svg";
export {default as Linkedin} from "-!svg-react-loader!./linkedin.svg";
export {default as Addresses} from "-!svg-react-loader!./addresses.svg";
export {default as Logout} from "-!svg-react-loader!./logout.svg";
export {default as HeartSvg} from "-!svg-react-loader!./heart.svg";
export {default as No} from "-!svg-react-loader!./no.svg";
export {default as PagesSVG} from "-!svg-react-loader!./page.svg";
export {default as ChangepasSvg} from "-!svg-react-loader!./paschange.svg";
export {default as PersonFill} from "-!svg-react-loader!./personFil.svg";
export {default as RemoveAddress} from "-!svg-react-loader!./remove-address.svg";
export {default as SearchssSvg} from "-!svg-react-loader!./searchsss.svg";
export {default as SucceSsvg} from "-!svg-react-loader!./succes.svg";
export {default as TrackingSVG} from "-!svg-react-loader!./tracking.svg";
export {default as CartTrash} from "-!svg-react-loader!./trashForCart.svg";
export {default as Twitter} from "-!svg-react-loader!./twitter.svg";
export {default as VektorMenu} from "-!svg-react-loader!./vektorMenu.svg";
export {default as Wish} from "-!svg-react-loader!./wish.svg";
export {default as WishCircle} from "-!svg-react-loader!./WishCircle.svg";
export {default as Wishlist16Svg} from "-!svg-react-loader!./wishlist-16.svg";
export {default as CartIcon} from "-!svg-react-loader!./CartIcon.svg";
export {default as SearchSvg} from "-!svg-react-loader!./Search.svg";
export {default as Yes} from "-!svg-react-loader!./yes.svg";
export {default as ZoomIn24Svg} from "-!svg-react-loader!./zoom-in-24.svg";
export {default as HomeMobile} from "-!svg-react-loader!./home_mobile.svg";
export {default as HomeMobileActive} from "-!svg-react-loader!./home_mobile_active.svg";
export {default as SignedInUser} from "-!svg-react-loader!./signed_in_user.svg";
export {default as HeartActive} from "-!svg-react-loader!./heart_active.svg";
export {default as CartActive} from "-!svg-react-loader!./cart_active.svg";
export {default as InputMinus} from "-!svg-react-loader!./input_minus.svg";
export {default as InputPlus} from "-!svg-react-loader!./input_plus.svg";
export {default as DropDownArrow} from "-!svg-react-loader!./drop_down_arrow.svg";
export {default as PostCatsFilterIcon} from "-!svg-react-loader!./post_cats_filter_icon.svg";
export {default as PostTagsFilterIcon} from "-!svg-react-loader!./post_tags_filter_icon.svg";
export {default as PostShareSvg} from "-!svg-react-loader!./post_share.svg";
export {default as SliderPrevArrow} from "-!svg-react-loader!./slider_prev_arrow.svg"
export {default as SliderNextArrow} from "-!svg-react-loader!./slider_next_arrow.svg"
export {default as ReviewStar} from "-!svg-react-loader!./review-star.svg"